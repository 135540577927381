<template>
	<div
		v-if="title || images.length"
		:id="$vnode.key"
		class="c-block-list-images-custom"
	>
		<LongReadTarget
			v-if="title && !accordionLevel && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" class="text-center mb-2xl" v-text="title" />

		<!-- Desktop or single image -->
		<div
			v-if="renderedImages && renderedImages.length"
			:class="[
				'c-block-list-images-custom__content-desktop',
				'>=768:block w-full',
				{
					hidden: renderedImages.length > 1,
				},
			]"
		>
			<div
				ref="desktopSlider"
				:class="[
					'relative w-full h-0',
					'transition-all duration-500 ease-smooth-out',
				]"
				:style="{
					paddingTop: 'calc((576 / 864) * 100%)',
				}"
			>
				<div
					:class="[
						'absolute top-1/2 left-1/2 w-full h-full',
						'flex justify-center items-center',
						'transform -translate-y-1/2 -translate-x-1/2',
					]"
				>
					<TransitionExt
						:duration="500"
						mode="out-in"
						name="block-list-images-custom__item"
					>
						<!-- Image -->
						<UmbracoImageExt
							v-if="images[index] && renderedImages[index]"
							:key="`image-${index}`"
							:aria-describedby="
								images[index].imageText
									? `${_uid}-caption-${index}`
									: null
							"
							:widths="[311, 480, 864]"
							:alt="images[index].altText"
							:source-url="
								images[index].cropUrl || images[index].url
							"
							:source-width="images[index].width"
							:source-height="images[index].height"
							:credit="images[index].photoCredits"
							:class="['max-w-full max-h-full']"
							:style="{
								width: `${renderedImages[index].renderedWidth}px`,
								height: `${renderedImages[index].renderedHeight}px`,
							}"
						/>
					</TransitionExt>
				</div>

				<!-- Previous Button -->
				<BaseButton
					v-if="renderedImages.length > 1"
					aria-label="Se forrige billede"
					:aria-disabled="index === 0"
					:class="[
						'absolute top-1/2 left-0 w-40 h-40',
						'transform -translate-x-1/2 -translate-y-1/2 -scale-x-100',
					]"
					dark
					@click="gotoPrevious"
				>
					<template #icon>
						<SvgIconCaret
							class="transform -rotate-90 translate-x-2"
						/>
					</template>
				</BaseButton>

				<!-- Next Button -->
				<BaseButton
					v-if="renderedImages.length > 1"
					aria-label="Se næste billede"
					:aria-disabled="index === renderedImages.length - 1"
					:class="[
						'absolute top-1/2 right-0 w-40 h-40',
						'transform translate-x-1/2 -translate-y-1/2',
					]"
					dark
					@click="gotoNext"
				>
					<template #icon>
						<SvgIconCaret
							class="transform -rotate-90 translate-x-2"
						/>
					</template>
				</BaseButton>
			</div>

			<!-- Caption -->
			<div
				v-if="images[index]"
				class="
					mt-md
					flex
					gap-md
					justify-center
					items-baseline
					text-center
				"
			>
				<span
					v-if="images.length > 1"
					class="
						text-image-pagination
						font-darker-grotesque font-bold
					"
					v-text="`${index + 1} / ${images.length}`"
				></span>

				<span
					v-if="images[index].imageText"
					:id="`${_uid}-caption-${index}`"
					:key="`${_uid}-caption-${index}`"
					class="s-rich-text text-teaser-sm"
					v-html="images[index].imageText"
				></span>
			</div>
		</div>

		<!-- Mobile (multiple images) -->
		<SwiperWrap
			v-if="images && images.length > 1"
			ref="mobileSlider"
			:move-by="1"
			:class="[
				'c-block-list-images-custom__content-mobile',
				'relative -mx-layout-margin >=768:mx-0',
				'>=768:hidden',
			]"
			:items-wrapper-class-names="[
				'flex w-full',
				'snap snap-x snap-mandatory',
				'pr-layout-margin >=768:pr-0',
				'>=768:px-0 >=768:space-x-0',

				{
					'overflow-x-auto': images.length >= 2,
					'overflow-x-hidden': images.length === 1,
				},
			]"
		>
			<!-- Items -->
			<template #items>
				<div
					v-for="(image, iindex) in images"
					ref="mobileItems"
					:key="`${image.id}-${iindex}`"
					:aria-labelledby="
						image.altText ? `${_uid}-caption-${image.id}` : null
					"
					:class="[
						'snap-start pl-layout-margin',
						'>=768:min-w-full >=768:rounded-0 >=768:pl-0',
					]"
				>
					<UmbracoImageExt
						:widths="[311, 480, 864]"
						:alt="image.altText"
						:source-url="image.cropUrl || image.url"
						:source-width="image.width"
						:source-height="image.height"
						:style="
							mobileHeight
								? `height: ${mobileHeight}px !important`
								: `width: calc(100% - 16px); aspect-ratio: ${image.width} / ${image.height};`
						"
						:credit="image.photoCredits"
					/>
				</div>
			</template>

			<!-- Caption -->
			<template #default="{ pagination }">
				<div
					v-if="images[pagination.index]"
					class="
						px-layout-margin
						mt-sm
						flex flex-col
						gap-xs
						items-center
						text-center
					"
				>
					<span
						v-if="images.length > 1"
						class="
							text-image-pagination
							font-darker-grotesque font-bold
						"
						v-text="`${pagination.index + 1} / ${pagination.count}`"
					></span>

					<span
						v-if="images[pagination.index].imageText"
						:id="`${_uid}-caption-${pagination.index}`"
						:key="`${_uid}-caption-${pagination.index}`"
						class="s-rich-text text-teaser-sm"
						v-html="images[pagination.index].imageText"
					></span>
				</div>
			</template>
		</SwiperWrap>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgIconCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'BlockListImagesCustom',

	components: {
		LongReadTarget,
		SwiperWrap,
		UmbracoImageExt,
		SvgIconCaret,
	},

	inject: {
		accordionLevel: {
			default: null,
		},
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		includeInToc: {
			type: Boolean,
			required: false,
		},
		images: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			index: 0,
			mobileOffset: 0,
			observer: null,

			desktopDimensions: {
				width: 0,
				height: 0,
			},

			mobileDimensions: {
				width: 0,
				height: 0,
			},
		};
	},

	computed: {
		// Used to show the correct ratio of image
		renderedImages() {
			let { width } = this.desktopDimensions;
			width = width || 864;
			const height = (width / 864) * 576;

			const newList =
				this.images?.map((e) => {
					let renderedWidth = Math.min(width, e.width);
					let renderedHeight = (e.height / e.width) * renderedWidth;

					if (renderedHeight > height) {
						renderedHeight = Math.min(height, e.height);
						renderedWidth = (e.width / e.height) * renderedHeight;
					}

					return {
						...e,
						renderedWidth,
						renderedHeight,
					};
				}) || [];
			return newList;
		},

		maxRenderedHeight() {
			const { width } = this.desktopDimensions;
			if (!width) {
				return null;
			}
			return (
				this.renderedImages?.[this.index]?.renderedHeight ||
				Math.max(
					0,
					...this.renderedImages.map((item) => item.renderedHeight)
				)
			);
		},

		mobileHeight() {
			let ratio = 0;
			let highestRatio = 0;
			this.images.forEach((e) => {
				const r = e.width / e.height;
				highestRatio = Math.max(highestRatio, r);
				ratio = highestRatio === r ? e.height / e.width : ratio;
			});

			let inset = 16;
			if (this.$refs?.mobileItems) {
				const { mobileItems } = this.$refs;
				const target = mobileItems[0] || mobileItems;
				inset = parseFloat(getComputedStyle(target).paddingLeft);
			}

			const calculatedInset = inset * (this.images.length >= 2 ? 3 : 2);
			const width = Math.max(
				0,
				this.mobileDimensions.width - calculatedInset
			);
			return Math.min(width * ratio, (195 / 311) * width);
		},
	},

	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();

		if (this.$refs?.desktopSlider || this.$refs?.mobileSlider) {
			this.observer = new IntersectionObserver(
				([{ isIntersecting }]) => {
					isIntersecting && this.onResize();
				},
				{ threshold: 0.1 }
			);

			const { desktopSlider, mobileSlider } = this.$refs;
			desktopSlider && this.observer.observe(desktopSlider);
			mobileSlider?.$el && this.observer.observe(mobileSlider.$el);
		}
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		gotoPrevious() {
			this.index !== 0 && this.index--;
		},
		gotoNext() {
			this.index !== this.images.length - 1 && this.index++;
		},

		onResize() {
			if (this.$refs?.desktopSlider) {
				const { desktopSlider } = this.$refs;
				const { width, height } = desktopSlider.getBoundingClientRect();
				this.desktopDimensions = { width, height };
			}

			if (this.$refs?.mobileSlider) {
				const mobileSlider = this.$refs.mobileSlider.$el;
				const { width } = mobileSlider.getBoundingClientRect();
				this.mobileDimensions = { width };

				/**
				 * This ain't optimal, but it's
				 * essentially just figuring out
				 * the right padding of the last
				 * mobile slider item.
				 */
				if (this.$refs.mobileItems) {
					const { mobileItems } = this.$refs;
					const { width: containerWidth } = this.mobileDimensions;

					const target = mobileItems[0] || mobileItems;
					const inset = parseFloat(
						getComputedStyle(target).paddingLeft
					);

					this.$nextTick(() => {
						const last = mobileItems[mobileItems.length - 1];
						const { width: itemWidth } =
							last.getBoundingClientRect();

						this.mobileOffset = containerWidth - itemWidth - inset;
					});
				}
			}
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-images-custom {
	& .c-umbraco-image img {
		@apply object-cover;
	}
}

.t-block-list-images-custom__item-enter-active,
.t-block-list-images-custom__item-leave-active {
	@apply transition-all ease-smooth-out;
}

.t-block-list-images-custom__item-enter,
.t-block-list-images-custom__item-leave-to {
	@apply opacity-0;
	/* transform: scale(1.02); */
}
</style>
