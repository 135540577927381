var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.images.length)?_c('div',{staticClass:"c-block-list-images-custom",attrs:{"id":_vm.$vnode.key}},[(_vm.title && !_vm.accordionLevel && _vm.includeInToc)?_c('LongReadTarget',{attrs:{"container-id":_vm.$vnode.key,"title":_vm.title}}):_vm._e(),_vm._v(" "),(_vm.title)?_c('BaseH2',{staticClass:"text-center mb-2xl",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.renderedImages && _vm.renderedImages.length)?_c('div',{class:[
			'c-block-list-images-custom__content-desktop',
			'>=768:block w-full',
			{
				hidden: _vm.renderedImages.length > 1,
			},
		]},[_c('div',{ref:"desktopSlider",class:[
				'relative w-full h-0',
				'transition-all duration-500 ease-smooth-out',
			],style:({
				paddingTop: 'calc((576 / 864) * 100%)',
			})},[_c('div',{class:[
					'absolute top-1/2 left-1/2 w-full h-full',
					'flex justify-center items-center',
					'transform -translate-y-1/2 -translate-x-1/2',
				]},[_c('TransitionExt',{attrs:{"duration":500,"mode":"out-in","name":"block-list-images-custom__item"}},[(_vm.images[_vm.index] && _vm.renderedImages[_vm.index])?_c('UmbracoImageExt',{key:`image-${_vm.index}`,class:['max-w-full max-h-full'],style:({
							width: `${_vm.renderedImages[_vm.index].renderedWidth}px`,
							height: `${_vm.renderedImages[_vm.index].renderedHeight}px`,
						}),attrs:{"aria-describedby":_vm.images[_vm.index].imageText
								? `${_vm._uid}-caption-${_vm.index}`
								: null,"widths":[311, 480, 864],"alt":_vm.images[_vm.index].altText,"source-url":_vm.images[_vm.index].cropUrl || _vm.images[_vm.index].url,"source-width":_vm.images[_vm.index].width,"source-height":_vm.images[_vm.index].height,"credit":_vm.images[_vm.index].photoCredits}}):_vm._e()],1)],1),_vm._v(" "),(_vm.renderedImages.length > 1)?_c('BaseButton',{class:[
					'absolute top-1/2 left-0 w-40 h-40',
					'transform -translate-x-1/2 -translate-y-1/2 -scale-x-100',
				],attrs:{"aria-label":"Se forrige billede","aria-disabled":_vm.index === 0,"dark":""},on:{"click":_vm.gotoPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconCaret',{staticClass:"transform -rotate-90 translate-x-2"})]},proxy:true}],null,false,4012573597)}):_vm._e(),_vm._v(" "),(_vm.renderedImages.length > 1)?_c('BaseButton',{class:[
					'absolute top-1/2 right-0 w-40 h-40',
					'transform translate-x-1/2 -translate-y-1/2',
				],attrs:{"aria-label":"Se næste billede","aria-disabled":_vm.index === _vm.renderedImages.length - 1,"dark":""},on:{"click":_vm.gotoNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconCaret',{staticClass:"transform -rotate-90 translate-x-2"})]},proxy:true}],null,false,4012573597)}):_vm._e()],1),_vm._v(" "),(_vm.images[_vm.index])?_c('div',{staticClass:"mt-md flex gap-md justify-center items-baseline text-center"},[(_vm.images.length > 1)?_c('span',{staticClass:"text-image-pagination font-darker-grotesque font-bold",domProps:{"textContent":_vm._s(`${_vm.index + 1} / ${_vm.images.length}`)}}):_vm._e(),_vm._v(" "),(_vm.images[_vm.index].imageText)?_c('span',{key:`${_vm._uid}-caption-${_vm.index}`,staticClass:"s-rich-text text-teaser-sm",attrs:{"id":`${_vm._uid}-caption-${_vm.index}`},domProps:{"innerHTML":_vm._s(_vm.images[_vm.index].imageText)}}):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.images && _vm.images.length > 1)?_c('SwiperWrap',{ref:"mobileSlider",class:[
			'c-block-list-images-custom__content-mobile',
			'relative -mx-layout-margin >=768:mx-0',
			'>=768:hidden',
		],attrs:{"move-by":1,"items-wrapper-class-names":[
			'flex w-full',
			'snap snap-x snap-mandatory',
			'pr-layout-margin >=768:pr-0',
			'>=768:px-0 >=768:space-x-0',

			{
				'overflow-x-auto': _vm.images.length >= 2,
				'overflow-x-hidden': _vm.images.length === 1,
			},
		]},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.images),function(image,iindex){return _c('div',{key:`${image.id}-${iindex}`,ref:"mobileItems",refInFor:true,class:[
					'snap-start pl-layout-margin',
					'>=768:min-w-full >=768:rounded-0 >=768:pl-0',
				],attrs:{"aria-labelledby":image.altText ? `${_vm._uid}-caption-${image.id}` : null}},[_c('UmbracoImageExt',{style:(_vm.mobileHeight
							? `height: ${_vm.mobileHeight}px !important`
							: `width: calc(100% - 16px); aspect-ratio: ${image.width} / ${image.height};`),attrs:{"widths":[311, 480, 864],"alt":image.altText,"source-url":image.cropUrl || image.url,"source-width":image.width,"source-height":image.height,"credit":image.photoCredits}})],1)})},proxy:true},{key:"default",fn:function({ pagination }){return [(_vm.images[pagination.index])?_c('div',{staticClass:"px-layout-margin mt-sm flex flex-col gap-xs items-center text-center"},[(_vm.images.length > 1)?_c('span',{staticClass:"text-image-pagination font-darker-grotesque font-bold",domProps:{"textContent":_vm._s(`${pagination.index + 1} / ${pagination.count}`)}}):_vm._e(),_vm._v(" "),(_vm.images[pagination.index].imageText)?_c('span',{key:`${_vm._uid}-caption-${pagination.index}`,staticClass:"s-rich-text text-teaser-sm",attrs:{"id":`${_vm._uid}-caption-${pagination.index}`},domProps:{"innerHTML":_vm._s(_vm.images[pagination.index].imageText)}}):_vm._e()]):_vm._e()]}}],null,false,242132886)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }